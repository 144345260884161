import { Container, Typography, Box, Card, CardContent, Grid, Button, CardMedia, Divider, useTheme, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HeroSection from '../components/HeroSection';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PrayerIcon from '@mui/icons-material/Church';
import HistoryIcon from '@mui/icons-material/History';
import VolunteerIcon from '@mui/icons-material/VolunteerActivism';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { alpha } from '@mui/material/styles';
import { VigilIcon, SundayIcon, WeekdayIcon, HolyDayIcon } from '../components/icons/MassIcons';
import { BaptismIcon, MarriageIcon, ConfessionIcon } from '../components/icons/SacramentIcons';
import { FoodBankIcon, YouthMinistryIcon, ElderlySupportIcon } from '../components/icons/OutreachIcons';
import NotificationSubscription from '../components/NotificationSubscription';
import LiveStream from '../components/LiveStream';

// Sample notices data
const notices = [
  {
    id: 1,
    title: 'Easter Sunday Mass',
    date: '2024-03-31',
    content: 'Join us for our special Easter Sunday Mass at 10:00 AM. All are welcome.',
    image: 'https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop',
  },
  {
    id: 2,
    title: 'Parish Meeting',
    date: '2024-03-28',
    content: 'Monthly parish meeting will be held in the parish hall after Mass.',
    image: 'https://images.unsplash.com/photo-1485795046599-702122cd1267?q=80&w=2070&auto=format&fit=crop',
  },
  {
    id: 3,
    title: 'First Holy Communion',
    date: '2024-03-25',
    content: 'First Holy Communion preparation classes will begin next week.',
    image: 'https://images.unsplash.com/photo-1545987796-200677ee1011?q=80&w=2070&auto=format&fit=crop',
  },
];

// Sample reflections data
const reflections = [
  {
    id: 1,
    title: 'Sunday Reflection - March 24, 2024',
    date: '2024-03-24',
    duration: '12:45',
    audioUrl: '/reflections/reflection-2024-03-24.mp3',
    image: 'https://images.unsplash.com/photo-1543968996-ee822b8176ba?q=80&w=2071&auto=format&fit=crop',
  },
  {
    id: 2,
    title: 'Sunday Reflection - March 17, 2024',
    date: '2024-03-17',
    duration: '11:30',
    audioUrl: '/reflections/reflection-2024-03-17.mp3',
    image: 'https://images.unsplash.com/photo-1543968996-ee822b8176ba?q=80&w=2071&auto=format&fit=crop',
  },
  {
    id: 3,
    title: 'Sunday Reflection - March 10, 2024',
    date: '2024-03-10',
    duration: '13:15',
    audioUrl: '/reflections/reflection-2024-03-10.mp3',
    image: 'https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop',
  },
];

// Add new data for upcoming events
const upcomingEvents = [
  {
    id: 1,
    title: 'Easter Triduum',
    date: '2024-03-28',
    time: '7:00 PM',
    description: 'Join us for the Holy Thursday Mass of the Lord\'s Supper.',
    image: 'https://images.unsplash.com/photo-1543968996-ee822b8176ba?q=80&w=2071&auto=format&fit=crop',
  },
  {
    id: 2,
    title: 'Youth Group Meeting',
    date: '2024-03-30',
    time: '3:00 PM',
    description: 'Monthly gathering for young parishioners aged 13-18.',
    image: 'https://images.unsplash.com/photo-1514896851111-410c2984a1c4?q=80&w=2070&auto=format&fit=crop',
  },
  {
    id: 3,
    title: 'Parish Picnic',
    date: '2024-04-07',
    time: '12:00 PM',
    description: 'Annual parish picnic in the church grounds. All welcome!',
    image: 'https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop',
  },
];

// Add new data for community outreach
const outreachPrograms = [
  {
    id: 1,
    title: 'Food Bank',
    description: 'Supporting local families in need through our weekly food collection.',
    icon: <FoodBankIcon />,
  },
  {
    id: 2,
    title: 'Youth Ministry',
    description: 'Engaging young people in faith through activities and discussions.',
    icon: <YouthMinistryIcon />,
  },
  {
    id: 3,
    title: 'Elderly Support',
    description: 'Providing companionship and assistance to elderly parishioners.',
    icon: <ElderlySupportIcon />,
  },
];

function Home() {
  const theme = useTheme();

  return (
    <>
      <HeroSection />
      
      <Container sx={{ py: 8 }}>
        {/* Welcome Message with Glassmorphism */}
        <Box 
          sx={{ 
            mb: 8, 
            textAlign: 'center', 
            maxWidth: 800, 
            mx: 'auto',
            p: 6,
            borderRadius: 4,
            background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.background.paper, 0.05)} 100%)`,
            backdropFilter: 'blur(10px)',
            boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
            border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
            position: 'relative',
            overflow: 'hidden',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `url('https://images.unsplash.com/photo-1543968996-ee822b8176ba?q=80&w=2071&auto=format&fit=crop')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: 0.05,
              zIndex: 0,
            }
          }}
        >
          <Box sx={{ position: 'relative', zIndex: 1 }}>
            <Typography 
              variant="h3" 
              component="h2" 
              gutterBottom 
              sx={{ 
                fontWeight: 700,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                backgroundClip: 'text',
                textFillColor: 'transparent',
                mb: 3,
              }}
            >
              Welcome to Our Parish
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, fontSize: '1.2rem', lineHeight: 1.8 }}>
              St. Patrick's Church in Ballyvaughan is a vibrant Catholic community serving the spiritual needs of our parish. 
              We invite you to join us in worship and become part of our parish family.
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              component={RouterLink} 
              to="/about"
              size="large"
              startIcon={<PlayArrowIcon />}
              sx={{
                px: 4,
                py: 1.5,
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
                  background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                },
              }}
            >
              Learn More About Us
            </Button>
          </Box>
        </Box>

        {/* Live Stream Section */}
        <LiveStream />

        {/* Mass Times Section with Animated Cards */}
        <Box id="mass-times" sx={{ mb: 8, textAlign: 'center' }}>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ 
              fontWeight: 700,
              mb: 4,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                borderRadius: 2,
              }
            }}
          >
            Mass Times
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            {[
              { title: 'Saturday Vigil', time: '7:00 PM', icon: <VigilIcon /> },
              { title: 'Sunday', time: '10:00 AM', icon: <SundayIcon /> },
              { title: 'Weekdays', time: '9:30 AM', icon: <WeekdayIcon /> },
              { title: 'Holy Days', time: '10:00 AM', icon: <HolyDayIcon /> }
            ].map((mass, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    '&:hover': { 
                      transform: 'translateY(-5px) scale(1.02)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                    } 
                  }}
                >
                  <CardContent sx={{ p: 3 }}>
                    <Box 
                      sx={{ 
                        mb: 2,
                        color: theme.palette.primary.main,
                        display: 'flex',
                        justifyContent: 'center',
                        '& svg': {
                          width: 40,
                          height: 40,
                        }
                      }}
                    >
                      {mass.icon}
                    </Box>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 600, 
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                      }}
                    >
                      {mass.title}
                    </Typography>
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        fontWeight: 700, 
                        color: theme.palette.text.primary,
                        textShadow: `0 2px 4px ${alpha(theme.palette.primary.main, 0.2)}`,
                      }}
                    >
                      {mass.time}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Gospel Reflection Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ 
              fontWeight: 700,
              textAlign: 'center',
              mb: 4,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                borderRadius: 2,
              }
            }}
          >
            This Week's Gospel Reflection
          </Typography>
          <Card 
            sx={{ 
              maxWidth: 800,
              mx: 'auto',
              transition: 'all 0.3s ease',
              background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
              backdropFilter: 'blur(10px)',
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
              }
            }}
          >
            <CardContent sx={{ p: 4 }}>
              <Typography 
                variant="h5" 
                gutterBottom 
                sx={{ 
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  mb: 3,
                }}
              >
                Fifth Sunday of Lent - March 17, 2024
              </Typography>
              
              <Typography 
                variant="h6" 
                gutterBottom 
                sx={{ 
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  mb: 2,
                }}
              >
                Gospel Reading: John 12:20-33
              </Typography>
              
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 4,
                  lineHeight: 1.8,
                  fontStyle: 'italic',
                  color: theme.palette.text.secondary,
                }}
              >
                "Unless a grain of wheat falls to the ground and dies, it remains just a grain of wheat; but if it dies, it produces much fruit."
              </Typography>

              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 3,
                  lineHeight: 1.8,
                }}
              >
                In this week's Gospel, Jesus uses the metaphor of a grain of wheat to teach us about the paradox of life through death. Just as a seed must be buried in the ground to grow and produce fruit, we too must be willing to let go of our own desires and ego to truly live in Christ.
              </Typography>

              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 3,
                  lineHeight: 1.8,
                }}
              >
                This message is particularly relevant during Lent, as we reflect on our own journey of transformation. What aspects of our lives need to "die" so that new life can emerge? How can we better serve others and follow Christ's example of self-giving love?
              </Typography>

              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<PlayArrowIcon />}
                  sx={{
                    borderRadius: 2,
                    px: 3,
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                    },
                  }}
                >
                  Listen to Full Reflection
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>

        {/* Sacraments Section with Modern Cards */}
        <Box sx={{ mb: 8 }}>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ 
              fontWeight: 700, 
              textAlign: 'center',
              mb: 4,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                borderRadius: 2,
              }
            }}
          >
            Sacraments
          </Typography>
          <Grid container spacing={3}>
            {[
              {
                title: 'Baptism',
                content: 'Baptisms are celebrated on Sundays after Mass. Please contact the parish office to schedule.',
                icon: <BaptismIcon />,
                color: '#4CAF50'
              },
              {
                title: 'Marriage',
                content: 'Couples should contact the parish at least 6 months before their intended wedding date.',
                icon: <MarriageIcon />,
                color: '#E91E63'
              },
              {
                title: 'Confession',
                content: 'Saturday: 6:00 PM - 6:45 PM or by appointment',
                icon: <ConfessionIcon />,
                color: '#2196F3'
              }
            ].map((sacrament, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${alpha(sacrament.color, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(sacrament.color, 0.1)}`,
                    '&:hover': {
                      transform: 'translateY(-5px) scale(1.02)',
                      boxShadow: `0 8px 24px ${alpha(sacrament.color, 0.15)}`,
                    }
                  }}
                >
                  <CardContent sx={{ p: 4, textAlign: 'center' }}>
                    <Box 
                      sx={{ 
                        mb: 2,
                        color: sacrament.color,
                        display: 'flex',
                        justifyContent: 'center',
                        '& svg': {
                          width: 48,
                          height: 48,
                        }
                      }}
                    >
                      {sacrament.icon}
                    </Box>
                    <Typography 
                      variant="h5" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 600,
                        color: sacrament.color,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                      }}
                    >
                      {sacrament.title}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        lineHeight: 1.8,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {sacrament.content}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ 
                        mt: 3,
                        borderColor: sacrament.color,
                        color: sacrament.color,
                        '&:hover': {
                          borderColor: sacrament.color,
                          backgroundColor: alpha(sacrament.color, 0.1),
                        },
                      }}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Upcoming Events Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography 
              variant="h4" 
              component="h2" 
              sx={{ 
                fontWeight: 700,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: 60,
                  height: 4,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  borderRadius: 2,
                }
              }}
            >
              Upcoming Events
            </Typography>
            <Button
              component={RouterLink}
              to="/events"
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<CalendarTodayIcon />}
              sx={{
                borderRadius: 2,
                px: 3,
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                backdropFilter: 'blur(10px)',
                border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                },
              }}
            >
              View Calendar
            </Button>
          </Box>
          <Grid container spacing={3}>
            {upcomingEvents.map((event) => (
              <Grid item xs={12} md={4} key={event.id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    '&:hover': { 
                      transform: 'translateY(-5px) scale(1.02)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                    } 
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={event.image}
                    alt={event.title}
                    sx={{
                      objectFit: 'cover',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      }
                    }}
                  />
                  <CardContent sx={{ p: 3 }}>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                      }}
                    >
                      {event.title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography color="text.secondary">
                        {new Date(event.date).toLocaleDateString('en-IE', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <AccessTimeIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography color="text.secondary">
                        {event.time}
                      </Typography>
                    </Box>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        lineHeight: 1.8,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {event.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Notices Section with Modern Cards */}
        <Box sx={{ mb: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography 
              variant="h4" 
              component="h2" 
              sx={{ 
                fontWeight: 700,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: 60,
                  height: 4,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  borderRadius: 2,
                }
              }}
            >
              Latest Notices
            </Typography>
            <Button
              component={RouterLink}
              to="/notices"
              variant="outlined"
              color="primary"
              size="large"
              sx={{
                borderRadius: 2,
                px: 3,
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                backdropFilter: 'blur(10px)',
                border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                },
              }}
            >
              View All
            </Button>
          </Box>
          <Grid container spacing={3}>
            {notices.map((notice) => (
              <Grid item xs={12} md={4} key={notice.id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    '&:hover': { 
                      transform: 'translateY(-5px) scale(1.02)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                    } 
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={notice.image}
                    alt={notice.title}
                    sx={{
                      objectFit: 'cover',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      }
                    }}
                  />
                  <CardContent sx={{ p: 3 }}>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                      }}
                    >
                      {notice.title}
                    </Typography>
                    <Typography 
                      color="text.secondary" 
                      gutterBottom 
                      sx={{ 
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        '&::before': {
                          content: '""',
                          display: 'inline-block',
                          width: 8,
                          height: 8,
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: '50%',
                          marginRight: 1,
                        }
                      }}
                    >
                      {new Date(notice.date).toLocaleDateString('en-IE', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        lineHeight: 1.8,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {notice.content}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Weekly Reflections Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography 
              variant="h4" 
              component="h2" 
              sx={{ 
                fontWeight: 700,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -8,
                  left: 0,
                  width: 60,
                  height: 4,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  borderRadius: 2,
                }
              }}
            >
              Weekly Reflections
            </Typography>
            <Button
              component={RouterLink}
              to="/reflections"
              variant="outlined"
              color="primary"
              size="large"
              sx={{
                borderRadius: 2,
                px: 3,
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                backdropFilter: 'blur(10px)',
                border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                },
              }}
            >
              View All
            </Button>
          </Box>
          <Grid container spacing={3}>
            {reflections.map((reflection) => (
              <Grid item xs={12} md={4} key={reflection.id}>
                <Card 
                  sx={{ 
                    height: '100%', 
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    '&:hover': { 
                      transform: 'translateY(-5px) scale(1.02)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                    } 
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={reflection.image}
                    alt={reflection.title}
                    sx={{
                      objectFit: 'cover',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      }
                    }}
                  />
                  <CardContent sx={{ p: 3 }}>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                      }}
                    >
                      {reflection.title}
                    </Typography>
                    <Typography 
                      color="text.secondary" 
                      gutterBottom 
                      sx={{ 
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        '&::before': {
                          content: '""',
                          display: 'inline-block',
                          width: 8,
                          height: 8,
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: '50%',
                          marginRight: 1,
                        }
                      }}
                    >
                      {new Date(reflection.date).toLocaleDateString('en-IE', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <AccessTimeIcon sx={{ mr: 1, fontSize: 16 }} />
                        Duration: {reflection.duration}
                      </Typography>
                      <IconButton 
                        color="primary"
                        sx={{
                          background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                          '&:hover': {
                            background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.2)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                          }
                        }}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Prayer Requests Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Box 
            sx={{ 
              p: 6, 
              borderRadius: 4,
              background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
              backdropFilter: 'blur(10px)',
              textAlign: 'center',
              position: 'relative',
              overflow: 'hidden',
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `url('https://images.unsplash.com/photo-1543968996-ee822b8176ba?q=80&w=2071&auto=format&fit=crop')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.1,
                zIndex: 0,
              }
            }}
          >
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <PrayerIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
              <Typography 
                variant="h4" 
                component="h2" 
                gutterBottom 
                sx={{ 
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase',
                  letterSpacing: 2,
                }}
              >
                Prayer Requests
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 4, 
                  maxWidth: 600, 
                  mx: 'auto', 
                  fontSize: '1.1rem',
                  lineHeight: 1.8,
                }}
              >
                Share your prayer intentions with our parish community. We will remember your intentions in our daily prayers.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<PrayerIcon />}
                sx={{
                  borderRadius: 2,
                  px: 4,
                  py: 1.5,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
                    background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
                  },
                }}
              >
                Submit Prayer Request
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Community Outreach Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ 
              fontWeight: 700, 
              textAlign: 'center',
              mb: 4,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                borderRadius: 2,
              }
            }}
          >
            Community Outreach
          </Typography>
          <Grid container spacing={3}>
            {outreachPrograms.map((program) => (
              <Grid item xs={12} md={4} key={program.id}>
                <Card 
                  sx={{ 
                    height: '100%',
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                    '&:hover': {
                      transform: 'translateY(-5px) scale(1.02)',
                      boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                    }
                  }}
                >
                  <CardContent sx={{ p: 4, textAlign: 'center' }}>
                    <Box 
                      sx={{ 
                        mb: 2,
                        color: theme.palette.primary.main,
                        display: 'flex',
                        justifyContent: 'center',
                        '& svg': {
                          width: 48,
                          height: 48,
                        }
                      }}
                    >
                      {program.icon}
                    </Box>
                    <Typography 
                      variant="h5" 
                      gutterBottom 
                      sx={{ 
                        fontWeight: 600,
                        color: theme.palette.primary.main,
                        textTransform: 'uppercase',
                        letterSpacing: 1,
                      }}
                    >
                      {program.title}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        lineHeight: 1.8,
                        color: theme.palette.text.secondary,
                        mb: 3,
                      }}
                    >
                      {program.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ 
                        mt: 2,
                        borderRadius: 2,
                        px: 3,
                        background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                        backdropFilter: 'blur(10px)',
                        border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                          background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                        },
                      }}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Parish History Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Box 
            sx={{ 
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 4,
              alignItems: 'center',
              p: 4,
              borderRadius: 4,
              background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
              backdropFilter: 'blur(10px)',
              border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
              position: 'relative',
              overflow: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `url('https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.05,
                zIndex: 0,
              }
            }}
          >
            <Box sx={{ flex: 1, position: 'relative', zIndex: 1 }}>
              <Typography 
                variant="h4" 
                component="h2" 
                gutterBottom 
                sx={{ 
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'uppercase',
                  letterSpacing: 1,
                  '&::before': {
                    content: '""',
                    display: 'inline-block',
                    width: 4,
                    height: 32,
                    background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                    marginRight: 2,
                    borderRadius: 2,
                  }
                }}
              >
                Our Parish History
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 3, 
                  fontSize: '1.1rem', 
                  lineHeight: 1.8,
                  color: theme.palette.text.secondary,
                }}
              >
                Founded in 1850, St. Patrick's Church has been a cornerstone of the Ballyvaughan community for over 170 years. 
                Our beautiful church building, with its distinctive architecture and rich history, stands as a testament to the 
                faith and dedication of generations of parishioners.
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<HistoryIcon />}
                sx={{
                  borderRadius: 2,
                  px: 3,
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                    background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.15)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                  },
                }}
              >
                Read More
              </Button>
            </Box>
            <Box 
              component="img"
              src="https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop"
              alt="Church History"
              sx={{
                width: { xs: '100%', md: '50%' },
                height: 300,
                objectFit: 'cover',
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                }
              }}
            />
          </Box>
        </Box>

        {/* Notification Subscription Section */}
        <Box sx={{ mb: 8 }}>
          <NotificationSubscription />
        </Box>

        {/* Contact & Location Section with Modern Design */}
        <Box sx={{ mb: 8 }}>
          <Typography 
            variant="h4" 
            component="h2" 
            gutterBottom 
            sx={{ 
              fontWeight: 700, 
              textAlign: 'center', 
              mb: 4,
              position: 'relative',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: 60,
                height: 4,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                borderRadius: 2,
              }
            }}
          >
            Contact & Location
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card 
                sx={{ 
                  height: '100%',
                  transition: 'all 0.3s ease',
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  '&:hover': {
                    transform: 'translateY(-5px) scale(1.02)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                  }
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Typography 
                    variant="h5" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 600, 
                      color: theme.palette.primary.main,
                      textTransform: 'uppercase',
                      letterSpacing: 1,
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <LocationOnIcon sx={{ mr: 2, color: 'primary.main', fontSize: 28 }} />
                    <Typography sx={{ fontSize: '1.1rem' }}>
                      St. Patrick's Church, Ballyvaughan, Co. Clare
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <PhoneIcon sx={{ mr: 2, color: 'primary.main', fontSize: 28 }} />
                    <Typography sx={{ fontSize: '1.1rem' }}>
                      +353 (0)65 XXX XXXX
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <EmailIcon sx={{ mr: 2, color: 'primary.main', fontSize: 28 }} />
                    <Typography sx={{ fontSize: '1.1rem' }}>
                      office@stpatricksballyvaughan.ie
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon sx={{ mr: 2, color: 'primary.main', fontSize: 28 }} />
                    <Typography sx={{ fontSize: '1.1rem' }}>
                      Parish Office Hours: Mon-Fri 9:30 AM - 1:30 PM
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card 
                sx={{ 
                  height: '100%',
                  transition: 'all 0.3s ease',
                  background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
                  backdropFilter: 'blur(10px)',
                  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  '&:hover': {
                    transform: 'translateY(-5px) scale(1.02)',
                    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                  }
                }}
              >
                <CardContent sx={{ p: 4 }}>
                  <Typography 
                    variant="h5" 
                    gutterBottom 
                    sx={{ 
                      fontWeight: 600, 
                      color: theme.palette.primary.main,
                      textTransform: 'uppercase',
                      letterSpacing: 1,
                    }}
                  >
                    Location Map
                  </Typography>
                  <Box
                    component="iframe"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2397.904927646325!2d-9.149241684158269!3d53.115893979934895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x485b054662d0edb7%3A0x4b13c69a3c8c1a72!2sSt%20Patrick&#39;s%20Church!5e0!3m2!1sen!2sie!4v1647887842013!5m2!1sen!2sie"
                    sx={{
                      border: 0,
                      width: '100%',
                      height: '300px',
                      borderRadius: 2,
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.02)',
                      }
                    }}
                    allowFullScreen=""
                    loading="lazy"
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default Home; 