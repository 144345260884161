import { Box, Typography, Container, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function HeroSection() {
  return (
    <Box
      sx={{
        backgroundImage: 'url(https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '70vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6))',
        },
      }}
    >
      <Container maxWidth={false}>
        <Box 
          sx={{ 
            position: 'relative', 
            color: 'white', 
            textAlign: 'center',
            maxWidth: '1200px',
            margin: '0 auto',
            padding: { xs: '0 20px', md: 0 }
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: '2.5rem', sm: '3.5rem', md: '4.5rem' },
              fontWeight: 700,
              mb: 2,
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
            }}
          >
            St. Patrick's Church
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
              mb: 4,
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
            }}
          >
            Ballyvaughan, Co. Clare
          </Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: '800px',
              margin: '0 auto 2rem',
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
            }}
          >
            Welcome to our parish community. Join us in worship, fellowship, and spiritual growth.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button
              component={RouterLink}
              to="/notices"
              variant="contained"
              size="large"
              sx={{
                fontSize: '1.1rem',
                padding: '12px 32px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'white',
                },
              }}
            >
              Latest Updates
            </Button>
            <Button
              component={RouterLink}
              to="#mass-times"
              variant="outlined"
              size="large"
              sx={{
                fontSize: '1.1rem',
                padding: '12px 32px',
                borderColor: 'white',
                color: 'white',
                '&:hover': {
                  borderColor: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
            >
              Mass Times
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default HeroSection; 