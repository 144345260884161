import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Paper, Container, Button } from '@mui/material';
import io from 'socket.io-client';
import { API_URL } from '../config';

function Broadcast() {
  const videoRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [error, setError] = useState(null);
  const mediaRecorder = useRef(null);
  const streamInterval = useRef(null);
  const streamChunks = useRef([]);

  useEffect(() => {
    // Initialize Socket.IO connection
    console.log('Initializing Socket.IO connection to:', API_URL);
    const newSocket = io(API_URL, {
      path: '/socket.io/',
      transports: ['polling', 'websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      autoConnect: true,
      withCredentials: true,
      forceNew: true,
      multiplex: false,
      upgrade: true,
      rememberUpgrade: true,
      perMessageDeflate: false,
      maxHttpBufferSize: 1e8
    });

    newSocket.on('connect', () => {
      console.log('Socket.IO connected:', {
        socketId: newSocket.id,
        transport: newSocket.io.engine.transport.name,
        readyState: newSocket.io.engine.readyState,
        protocol: newSocket.io.engine.protocol
      });
      // Register as broadcaster
      console.log('Registering as broadcaster...');
      newSocket.emit('register-broadcaster');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket.IO connection error:', {
        message: error.message,
        description: error.description,
        context: error.context,
        stack: error.stack,
        type: error.type,
        code: error.code
      });
      setError('Failed to connect to the server');
    });

    newSocket.on('error', (error) => {
      console.error('Socket error:', {
        message: error.message,
        description: error.description,
        context: error.context,
        stack: error.stack,
        type: error.type,
        code: error.code
      });
    });

    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', {
        reason,
        wasConnected: newSocket.connected,
        transport: newSocket.io.engine.transport.name,
        readyState: newSocket.io.engine.readyState
      });
      stopStreaming();
    });

    setSocket(newSocket);

    // Cleanup on unmount
    return () => {
      console.log('Cleaning up Broadcast component...');
      stopStreaming();
      newSocket.close();
    };
  }, []);

  const startStreaming = async () => {
    try {
      console.log('Starting stream capture...');
      
      // Check if we have a valid socket connection
      if (!socket || !socket.connected) {
        console.error('Cannot start streaming: No active socket connection');
        setError('No active connection to server');
        return;
      }

      // Check if MediaRecorder is supported
      if (!window.MediaRecorder) {
        console.error('MediaRecorder not supported in this browser');
        setError('Your browser does not support video recording');
        return;
      }

      // Check supported MIME types
      const supportedMimeTypes = MediaRecorder.isTypeSupported('video/webm;codecs=vp8,opus');
      console.log('Supported MIME types:', {
        'video/webm;codecs=vp8,opus': supportedMimeTypes,
        'video/webm': MediaRecorder.isTypeSupported('video/webm'),
        'video/mp4': MediaRecorder.isTypeSupported('video/mp4')
      });

      if (!supportedMimeTypes) {
        console.error('Required codec not supported');
        setError('Your browser does not support the required video codec');
        return;
      }

      // Request camera and microphone permissions
      console.log('Requesting media permissions...');
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          frameRate: { ideal: 30 }
        },
        audio: true
      });
      console.log('Got media stream:', {
        tracks: stream.getTracks().map(track => ({
          kind: track.kind,
          label: track.label,
          enabled: track.enabled,
          muted: track.muted,
          readyState: track.readyState
        }))
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        console.log('Set video source object');
      }

      // Create MediaRecorder with specific settings
      console.log('Creating MediaRecorder...');
      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType: 'video/webm;codecs=vp8,opus',
        videoBitsPerSecond: 800000, // Reduce to 800 Kbps for better stability
        audioBitsPerSecond: 128000  // Set audio bitrate to 128 Kbps
      });
      console.log('MediaRecorder created with settings:', {
        mimeType: mediaRecorder.current.mimeType,
        state: mediaRecorder.current.state,
        videoBitsPerSecond: mediaRecorder.current.videoBitsPerSecond,
        audioBitsPerSecond: mediaRecorder.current.audioBitsPerSecond
      });

      // Handle data available event
      mediaRecorder.current.ondataavailable = (event) => {
        console.log('Data available from MediaRecorder:', {
          size: event.data.size,
          type: event.data.type,
          socketConnected: socket?.connected,
          socketId: socket?.id,
          timestamp: Date.now()
        });
        
        if (event.data.size > 0 && socket && socket.connected) {
          // Convert Blob to ArrayBuffer before sending
          event.data.arrayBuffer().then(buffer => {
            // Check if the buffer is too large
            if (buffer.byteLength > 500000) { // 500KB limit per chunk
              console.warn('Buffer too large, splitting:', buffer.byteLength);
              // Split the buffer into smaller chunks
              const chunkSize = 450000; // 450KB chunks
              for (let i = 0; i < buffer.byteLength; i += chunkSize) {
                const chunk = buffer.slice(i, i + chunkSize);
                const uint8Array = new Uint8Array(chunk);
                socket.emit('stream-data', uint8Array, (error) => {
                  if (error) {
                    console.error('Error sending stream chunk:', error);
                  }
                });
                console.log('Sent stream chunk:', {
                  chunkIndex: Math.floor(i / chunkSize),
                  totalChunks: Math.ceil(buffer.byteLength / chunkSize),
                  chunkSize: uint8Array.length,
                  timestamp: Date.now()
                });
              }
              return;
            }
            
            console.log('Converted blob to buffer:', {
              bufferSize: buffer.byteLength,
              socketConnected: socket.connected,
              socketId: socket.id,
              timestamp: Date.now()
            });
            
            // Create a new Uint8Array from the buffer
            const uint8Array = new Uint8Array(buffer);
            socket.emit('stream-data', uint8Array, (error) => {
              if (error) {
                console.error('Error sending stream data:', error);
              }
            });
            console.log('Sent stream data:', {
              dataSize: uint8Array.length,
              socketConnected: socket.connected,
              socketId: socket.id,
              timestamp: Date.now()
            });
          }).catch(err => {
            console.error('Error converting blob to buffer:', {
              error: err.message,
              stack: err.stack,
              socketConnected: socket?.connected,
              socketId: socket?.id,
              timestamp: Date.now()
            });
          });
        } else {
          console.log('Skipping data send:', {
            dataSize: event.data.size,
            socketConnected: socket?.connected,
            socketId: socket?.id,
            timestamp: Date.now()
          });
        }
      };

      // Add error handler for MediaRecorder
      mediaRecorder.current.onerror = (error) => {
        console.error('MediaRecorder error:', error);
        setError(`MediaRecorder error: ${error.message || 'Unknown error'}`);
        stopStreaming();
      };

      // Start recording with larger time slices
      console.log('Starting MediaRecorder...');
      mediaRecorder.current.start(1000); // Send data every 1 second for more stable chunks
      setIsStreaming(true);
      setError(null);
    } catch (err) {
      console.error('Error starting stream:', {
        error: err.message,
        stack: err.stack,
        name: err.name,
        code: err.code,
        constraint: err.constraint
      });
      setError(`Failed to start streaming: ${err.message}`);
    }
  };

  const stopStreaming = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      setIsStreaming(false);
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Broadcast Stream
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <video
              ref={videoRef}
              autoPlay
              muted
              playsInline
              style={{
                width: '100%',
                maxWidth: '640px',
                borderRadius: '8px',
                backgroundColor: '#000',
                aspectRatio: '16/9'
              }}
            />
            <Box sx={{ mt: 2 }}>
              {!isStreaming ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={startStreaming}
                  disabled={!socket}
                >
                  Start Streaming
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  onClick={stopStreaming}
                >
                  Stop Streaming
                </Button>
              )}
            </Box>
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            {!socket && (
              <Typography color="warning.main" sx={{ mt: 2 }}>
                Connecting to server...
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Broadcast; 