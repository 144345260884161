import { Box, Card, CardContent, Typography, IconButton, useTheme, Button } from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { alpha } from '@mui/material/styles';

function LiveStream() {
  const theme = useTheme();

  return (
    <Card 
      sx={{ 
        mb: 4,
        background: theme.palette.background.paper,
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      }}
    >
      <CardContent sx={{ p: 0 }}>
        {/* Stream Header */}
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            p: 1,
            pl: 2,
            borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            background: alpha(theme.palette.primary.main, 0.05),
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: theme.palette.error.main,
                mr: 1,
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': { transform: 'scale(1)', opacity: 1 },
                  '50%': { transform: 'scale(1.5)', opacity: 0.5 },
                  '100%': { transform: 'scale(1)', opacity: 1 },
                },
              }}
            />
            <Typography variant="subtitle2" sx={{ color: theme.palette.error.main, fontWeight: 600, mr: 1 }}>
              LIVE
            </Typography>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}>
              Sunday Mass
            </Typography>
          </Box>
        </Box>

        {/* Stream View */}
        <Box 
          sx={{ 
            position: 'relative',
            paddingTop: '56.25%', // 16:9 aspect ratio
            backgroundColor: '#000',
          }}
        >
          <Box 
            component="img"
            src="https://images.unsplash.com/photo-1438032005730-c779502df39b?q=80&w=2071&auto=format&fit=crop"
            alt="Live Mass Stream"
            sx={{ 
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              animation: 'subtle-zoom 30s infinite alternate',
              '@keyframes subtle-zoom': {
                '0%': { transform: 'scale(1)' },
                '100%': { transform: 'scale(1.03)' },
              },
            }}
          />
          
          {/* Stream Overlay */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 16,
              right: 16,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                background: 'rgba(0,0,0,0.7)',
                backdropFilter: 'blur(4px)',
                borderRadius: 1,
                px: 2,
                py: 0.5,
              }}
            >
              <Typography variant="caption" sx={{ color: 'white' }}>
                St. Patrick's Church, Ballyvaughan
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default LiveStream; 