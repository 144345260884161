import { Container, Typography, Card, CardContent, Box, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useState } from 'react';

// Sample reflections data - in a real app, this would come from a backend
const reflections = [
  {
    id: 1,
    title: 'Sunday Reflection - March 24, 2024',
    date: '2024-03-24',
    duration: '12:45',
    audioUrl: '/reflections/reflection-2024-03-24.mp3',
  },
  {
    id: 2,
    title: 'Sunday Reflection - March 17, 2024',
    date: '2024-03-17',
    duration: '11:30',
    audioUrl: '/reflections/reflection-2024-03-17.mp3',
  },
  {
    id: 3,
    title: 'Sunday Reflection - March 10, 2024',
    date: '2024-03-10',
    duration: '13:15',
    audioUrl: '/reflections/reflection-2024-03-10.mp3',
  },
];

function Reflections() {
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [audio] = useState(new Audio());

  const handlePlayPause = (reflection) => {
    if (currentlyPlaying === reflection.id) {
      audio.pause();
      setCurrentlyPlaying(null);
    } else {
      audio.src = reflection.audioUrl;
      audio.play();
      setCurrentlyPlaying(reflection.id);
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Weekly Reflections
      </Typography>
      
      <Box sx={{ mt: 3 }}>
        {reflections.map((reflection) => (
          <Card key={reflection.id} sx={{ mb: 2 }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {reflection.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {new Date(reflection.date).toLocaleDateString('en-IE', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Duration: {reflection.duration}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => handlePlayPause(reflection)}
                  color="primary"
                  size="large"
                >
                  {currentlyPlaying === reflection.id ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
}

export default Reflections; 