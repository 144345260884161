import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Navbar() {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          sx={{
            flexGrow: 1,
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          St. Patrick's Church, Ballyvaughan
        </Typography>
        <Box>
          <Button
            color="inherit"
            component={RouterLink}
            to="/"
            sx={{ mx: 1 }}
          >
            Home
          </Button>
          <Button
            color="inherit"
            component={RouterLink}
            to="/notices"
            sx={{ mx: 1 }}
          >
            Notices
          </Button>
          <Button
            color="inherit"
            component={RouterLink}
            to="/reflections"
            sx={{ mx: 1 }}
          >
            Reflections
          </Button>
          <Button
            color="inherit"
            component={RouterLink}
            to="/broadcast"
            sx={{ mx: 1 }}
          >
            Broadcast
          </Button>
          <Button
            color="inherit"
            component={RouterLink}
            to="/watch"
            sx={{ mx: 1 }}
          >
            Watch
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar; 