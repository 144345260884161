import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Snackbar, 
  Alert,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  Divider
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import SmsIcon from '@mui/icons-material/Sms';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { alpha } from '@mui/material/styles';

const API_BASE_URL = 'https://api.ballyvaughanfanoreparish.com';

const NotificationSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsSubscribed, setSmsSubscribed] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    checkSubscription();
    checkSmsSubscription();
  }, []);

  const checkSubscription = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.getSubscription();
      setSubscription(subscription);
    } catch (err) {
      console.error('Error checking subscription:', err);
      setError('Failed to check notification subscription status');
    }
  };

  const checkSmsSubscription = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/notifications/sms-status`);
      const data = await response.json();
      setSmsSubscribed(data.subscribed);
      if (data.phoneNumber) {
        setPhoneNumber(data.phoneNumber);
      }
    } catch (err) {
      console.error('Error checking SMS subscription:', err);
    }
  };

  const validatePhoneNumber = (number) => {
    // Basic Irish phone number validation
    const phoneRegex = /^(\+353|0)[1-9]\d{8}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (event) => {
    const number = event.target.value;
    setPhoneNumber(number);
    if (!validatePhoneNumber(number)) {
      setPhoneError('Please enter a valid Irish phone number');
    } else {
      setPhoneError('');
    }
  };

  const subscribeToNotifications = async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY
      });

      // Send subscription to backend
      await fetch(`${API_BASE_URL}/api/notifications/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscription),
      });

      setSubscription(subscription);
      setSuccess('Successfully subscribed to push notifications!');
    } catch (err) {
      console.error('Error subscribing to notifications:', err);
      setError('Failed to subscribe to push notifications');
    }
  };

  const unsubscribeFromNotifications = async () => {
    try {
      if (subscription) {
        await subscription.unsubscribe();
        setSubscription(null);
        setSuccess('Successfully unsubscribed from push notifications');
      }
    } catch (err) {
      console.error('Error unsubscribing from notifications:', err);
      setError('Failed to unsubscribe from push notifications');
    }
  };

  const subscribeToSms = async () => {
    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneError('Please enter a valid Irish phone number');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/notifications/sms-subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber }),
      });

      if (!response.ok) {
        throw new Error('Failed to subscribe to SMS notifications');
      }

      setSmsSubscribed(true);
      setSuccess('Successfully subscribed to SMS notifications!');
    } catch (err) {
      console.error('Error subscribing to SMS:', err);
      setError('Failed to subscribe to SMS notifications');
    }
  };

  const unsubscribeFromSms = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/notifications/sms-unsubscribe`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to unsubscribe from SMS notifications');
      }

      setSmsSubscribed(false);
      setPhoneNumber('');
      setSuccess('Successfully unsubscribed from SMS notifications');
    } catch (err) {
      console.error('Error unsubscribing from SMS:', err);
      setError('Failed to unsubscribe from SMS notifications');
    }
  };

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.paper, 0.1)} 100%)`,
        backdropFilter: 'blur(10px)',
        border: (theme) => `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
      }}
    >
      <Box>
        <Typography variant="h6" gutterBottom>
          Parish Notifications
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Stay updated with mass times, events, and important announcements
        </Typography>
      </Box>

      <Divider />

      {/* Push Notifications Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Push Notifications
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Receive instant notifications in your browser
          </Typography>
        </Box>
        
        <Tooltip title={subscription ? "Unsubscribe from push notifications" : "Subscribe to push notifications"}>
          <IconButton
            onClick={subscription ? unsubscribeFromNotifications : subscribeToNotifications}
            color={subscription ? "primary" : "default"}
            sx={{
              background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
              '&:hover': {
                background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.2)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
              }
            }}
          >
            {subscription ? <NotificationsIcon /> : <NotificationsOffIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      <Divider />

      {/* SMS Notifications Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="subtitle1" gutterBottom>
              SMS Notifications
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Receive text messages for important updates
            </Typography>
          </Box>
          
          <Tooltip title={smsSubscribed ? "Unsubscribe from SMS notifications" : "Subscribe to SMS notifications"}>
            <IconButton
              onClick={smsSubscribed ? unsubscribeFromSms : subscribeToSms}
              color={smsSubscribed ? "primary" : "default"}
              disabled={!smsSubscribed && (!phoneNumber || !!phoneError)}
              sx={{
                background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.dark, 0.05)} 100%)`,
                '&:hover': {
                  background: (theme) => `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.2)} 0%, ${alpha(theme.palette.primary.dark, 0.1)} 100%)`,
                }
              }}
            >
              {smsSubscribed ? <SmsIcon /> : <SmsFailedIcon />}
            </IconButton>
          </Tooltip>
        </Box>

        {!smsSubscribed && (
          <TextField
            fullWidth
            label="Phone Number"
            placeholder="+353 8X XXX XXXX"
            value={phoneNumber}
            onChange={handlePhoneChange}
            error={!!phoneError}
            helperText={phoneError || "Enter your Irish phone number"}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: 'primary.main',
                },
              },
            }}
          />
        )}
      </Box>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!success} 
        autoHideDuration={6000} 
        onClose={() => setSuccess(null)}
      >
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default NotificationSubscription; 