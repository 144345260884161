import { Container, Typography, Card, CardContent, Box } from '@mui/material';

// Sample notices data - in a real app, this would come from a backend
const notices = [
  {
    id: 1,
    title: 'Easter Sunday Mass',
    date: '2024-03-31',
    content: 'Join us for our special Easter Sunday Mass at 10:00 AM. All are welcome.',
  },
  {
    id: 2,
    title: 'Parish Meeting',
    date: '2024-03-28',
    content: 'Monthly parish meeting will be held in the parish hall after Mass.',
  },
  {
    id: 3,
    title: 'First Holy Communion',
    date: '2024-03-25',
    content: 'First Holy Communion preparation classes will begin next week.',
  },
];

function Notices() {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Parish Notices
      </Typography>
      
      <Box sx={{ mt: 3 }}>
        {notices.map((notice) => (
          <Card key={notice.id} sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" component="h2" gutterBottom>
                {notice.title}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                {new Date(notice.date).toLocaleDateString('en-IE', {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Typography>
              <Typography variant="body1">
                {notice.content}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Container>
  );
}

export default Notices; 