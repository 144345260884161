import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Typography, Paper, Container } from '@mui/material';
import io from 'socket.io-client';
import { API_URL } from '../config';

function Watch() {
  // Add MediaSource state tracking with timing
  const mediaSourceState = useRef({
    lastState: null,
    stateChanges: [],
    lastError: null,
    timing: {
      initToOpen: null,
      openToBuffer: null,
      bufferToReady: null
    }
  });
  
  // Add WebSocket state tracking with timing
  const socketState = useRef({
    lastState: null,
    stateChanges: [],
    lastError: null,
    upgrades: [],
    timing: {
      connectToUpgrade: null,
      upgradeToData: null
    }
  });

  // Add render reason tracking with more context
  const renderReason = useRef('initial');
  const renderContext = useRef({});
  console.log('Watch component rendering:', {
    reason: renderReason.current,
    context: renderContext.current,
    mediaSourceState: mediaSourceState.current,
    socketState: socketState.current,
    timestamp: Date.now()
  });
  
  // Add render count tracking with performance metrics
  const renderCount = useRef(0);
  const renderTimes = useRef([]);
  const prevStates = useRef({
    isConnected: null,
    videoState: null,
    error: null,
    socket: null
  });

  // Add performance metrics tracking with correlations
  const performanceMetrics = useRef({
    initializationTime: null,
    socketConnectTime: null,
    mediaSourceOpenTime: null,
    firstFrameTime: null,
    stateTransitions: [],
    correlations: {
      socketToMedia: null,
      mediaToFrame: null,
      totalStartup: null,
      mountToInit: null,
      initToBuffer: null,
      bufferToFirstData: null,
      socketLatency: null
    },
    dataMetrics: {
      firstDataReceived: null,
      totalBytesReceived: 0,
      chunkCount: 0,
      averageChunkSize: 0,
      lastDataTimestamp: null,
      dataRate: 0,
      droppedFrames: 0,
      bufferHealth: {
        bufferSize: 0,
        bufferDuration: 0,
        underflowCount: 0,
        lastUnderflow: null,
        overflowCount: 0,
        lastOverflow: null
      },
      playbackMetrics: {
        totalPlayTime: 0,
        totalStallTime: 0,
        stallCount: 0,
        averageStallDuration: 0,
        lastStallTime: null,
        playbackRate: 1,
        qualityChanges: []
      }
    }
  });

  const trackPerformanceMetric = (metric, value, correlate = true) => {
    const currentTime = Date.now();
    const prevMetrics = { ...performanceMetrics.current };
    
    performanceMetrics.current = {
      ...performanceMetrics.current,
      [metric]: value,
      stateTransitions: [
        ...performanceMetrics.current.stateTransitions,
        {
          metric,
          value,
          timestamp: currentTime
        }
      ]
    };

    // Calculate correlations
    if (correlate) {
      const { componentMount, socketConnectTime, mediaSourceOpenTime, firstFrameTime } = performanceMetrics.current;
      
      performanceMetrics.current.correlations = {
        ...performanceMetrics.current.correlations,
        socketToMedia: socketConnectTime && mediaSourceOpenTime ? mediaSourceOpenTime - socketConnectTime : null,
        mediaToFrame: mediaSourceOpenTime && firstFrameTime ? firstFrameTime - mediaSourceOpenTime : null,
        totalStartup: socketConnectTime && firstFrameTime ? firstFrameTime - socketConnectTime : null,
        mountToInit: componentMount && mediaSourceOpenTime ? mediaSourceOpenTime - componentMount : null,
        initToBuffer: mediaSourceOpenTime && performanceMetrics.current.dataMetrics.firstDataReceived ? 
          performanceMetrics.current.dataMetrics.firstDataReceived - mediaSourceOpenTime : null
      };

      // Calculate data rate if we have received data
      if (performanceMetrics.current.dataMetrics.lastDataTimestamp && 
          performanceMetrics.current.dataMetrics.totalBytesReceived > 0) {
        const timeSpan = currentTime - performanceMetrics.current.dataMetrics.firstDataReceived;
        performanceMetrics.current.dataMetrics.dataRate = 
          (performanceMetrics.current.dataMetrics.totalBytesReceived / (timeSpan / 1000)).toFixed(2);
      }
    }

    console.log('Performance metric:', {
      metric,
      value,
      correlations: performanceMetrics.current.correlations,
      dataMetrics: performanceMetrics.current.dataMetrics,
      history: performanceMetrics.current,
      timestamp: currentTime,
      timeSinceLast: prevMetrics[metric] ? currentTime - prevMetrics[metric] : null
    });
  };

  useEffect(() => {
    const renderTime = Date.now();
    renderCount.current += 1;
    renderTimes.current.push(renderTime);

    // Calculate time since last render
    const timeSinceLastRender = renderTimes.current.length > 1 
      ? renderTime - renderTimes.current[renderTimes.current.length - 2]
      : 0;

    console.log('Render completed:', {
      count: renderCount.current,
      reason: renderReason.current,
      context: renderContext.current,
      timeSinceLastRender,
      stateChanges: {
        isConnected: prevStates.current.isConnected !== isConnected ? {
          from: prevStates.current.isConnected,
          to: isConnected,
          context: renderContext.current.isConnected
        } : null,
        videoState: prevStates.current.videoState !== videoState ? {
          from: prevStates.current.videoState,
          to: videoState,
          context: renderContext.current.videoState
        } : null,
        error: prevStates.current.error !== error ? {
          from: prevStates.current.error,
          to: error,
          context: renderContext.current.error
        } : null,
        socket: prevStates.current.socket !== socket ? {
          from: prevStates.current.socket ? 'exists' : 'null',
          to: socket ? 'exists' : 'null',
          context: renderContext.current.socket
        } : null
      },
      timestamp: renderTime
    });

    // Update previous states
    prevStates.current = {
      isConnected,
      videoState,
      error,
      socket
    };

    // Clear render context after logging
    renderContext.current = {};
  });

  const videoRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const isSourceBufferReady = useRef(false);
  const streamQueue = useRef([]);
  const isProcessingQueue = useRef(false);
  const reconnectTimeoutRef = useRef(null);
  const [videoState, setVideoState] = useState('waiting');
  const initializationAttempts = useRef(0);
  const maxInitializationAttempts = 3;

  // Wrap state setters with enhanced logging
  const setIsConnectedWithLog = useCallback((value, context = {}) => {
    renderReason.current = `isConnected: ${value}`;
    renderContext.current = { isConnected: context };
    console.log('setIsConnected called:', {
      previousValue: isConnected,
      newValue: value,
      context,
      renderCount: renderCount.current,
      timestamp: Date.now()
    });
    setIsConnected(value);
  }, [isConnected]);

  const setErrorWithLog = useCallback((value, context = {}) => {
    renderReason.current = `error: ${value}`;
    renderContext.current = { error: context };
    console.log('setError called:', {
      previousValue: error,
      newValue: value,
      context,
      renderCount: renderCount.current,
      timestamp: Date.now()
    });
    setError(value);
  }, [error]);

  const setVideoStateWithLog = useCallback((value, context = {}) => {
    renderReason.current = `videoState: ${value}`;
    renderContext.current = { videoState: context };
    console.log('setVideoState called:', {
      previousValue: videoState,
      newValue: value,
      context,
      renderCount: renderCount.current,
      timestamp: Date.now()
    });
    setVideoState(value);
  }, [videoState]);

  const processStreamQueue = async () => {
    if (isProcessingQueue.current || !isSourceBufferReady.current || !sourceBufferRef.current) {
      console.log('Skipping queue processing:', {
        isProcessing: isProcessingQueue.current,
        isSourceBufferReady: isSourceBufferReady.current,
        hasSourceBuffer: !!sourceBufferRef.current,
        sourceBufferState: sourceBufferRef.current?.updating ? 'updating' : 'ready',
        queueLength: streamQueue.current.length,
        timestamp: Date.now()
      });
      return;
    }

    isProcessingQueue.current = true;
    
    try {
      // Process chunks in batches for better performance
      const maxBatchSize = 5; // Process up to 5 chunks at once
      const batchTimeout = 100; // Wait 100ms between batches
      
      while (streamQueue.current.length > 0) {
        const batch = streamQueue.current.splice(0, maxBatchSize);
        const totalSize = batch.reduce((sum, chunk) => sum + chunk.length, 0);
        
        console.log('Processing batch:', {
          batchSize: batch.length,
          totalSize,
          remainingQueueLength: streamQueue.current.length,
          timestamp: Date.now()
        });

        // Combine chunks in the batch
        const combinedData = new Uint8Array(totalSize);
        let offset = 0;
        for (const chunk of batch) {
          combinedData.set(chunk, offset);
          offset += chunk.length;
        }

        if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
          try {
            await new Promise((resolve, reject) => {
              const handleUpdate = () => {
                sourceBufferRef.current.removeEventListener('updateend', handleUpdate);
                resolve();
              };
              const handleError = (event) => {
                sourceBufferRef.current.removeEventListener('error', handleError);
                reject(new Error('SourceBuffer error: ' + event.message));
              };
              
              sourceBufferRef.current.addEventListener('updateend', handleUpdate);
              sourceBufferRef.current.addEventListener('error', handleError);
              
              try {
                sourceBufferRef.current.appendBuffer(combinedData);
              } catch (error) {
                sourceBufferRef.current.removeEventListener('updateend', handleUpdate);
                sourceBufferRef.current.removeEventListener('error', handleError);
                reject(error);
              }
            });

            console.log('Batch processed successfully:', {
              batchSize: batch.length,
              totalSize,
              remainingQueueLength: streamQueue.current.length,
              timestamp: Date.now()
            });

            // Add a small delay between batches to prevent overwhelming the SourceBuffer
            if (streamQueue.current.length > 0) {
              await new Promise(resolve => setTimeout(resolve, batchTimeout));
            }
          } catch (error) {
            console.error('Error appending buffer:', {
              error: error.message,
              stack: error.stack,
              batchSize: batch.length,
              totalSize,
              timestamp: Date.now()
            });
            
            // If we get a QuotaExceededError, try to make room by removing old data
            if (error.name === 'QuotaExceededError' && sourceBufferRef.current.buffered.length > 0) {
              const start = sourceBufferRef.current.buffered.start(0);
              const end = sourceBufferRef.current.buffered.end(0);
              const midPoint = (start + end) / 2;
              
              try {
                sourceBufferRef.current.remove(start, midPoint);
                // Re-add the failed batch to the front of the queue
                streamQueue.current.unshift(...batch);
              } catch (removeError) {
                console.error('Error removing old buffer:', removeError);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in processStreamQueue:', {
        error: error.message,
        stack: error.stack,
        queueLength: streamQueue.current.length,
        timestamp: Date.now()
      });
    } finally {
      isProcessingQueue.current = false;
    }
  };

  const cleanupMediaSource = () => {
    if (mediaSourceRef.current) {
      console.log('Cleaning up existing MediaSource');
      try {
        if (mediaSourceRef.current.readyState === 'open') {
          mediaSourceRef.current.endOfStream();
        }
        if (videoRef.current && videoRef.current.src) {
          URL.revokeObjectURL(videoRef.current.src);
        }
        // Clear any existing event listeners
        mediaSourceRef.current.removeEventListener('sourceopen', null);
        mediaSourceRef.current.removeEventListener('sourceended', null);
        mediaSourceRef.current.removeEventListener('sourceclose', null);
      } catch (e) {
        console.error('Error during MediaSource cleanup:', e);
      }
    }
    mediaSourceRef.current = null;
    sourceBufferRef.current = null;
    isSourceBufferReady.current = false;
    streamQueue.current = [];
    isProcessingQueue.current = false;
  };

  const trackMediaSourceState = (state, error = null) => {
    const currentTime = Date.now();
    const prevState = mediaSourceState.current.lastState;
    
    // Calculate timing between state transitions
    if (prevState === 'initializing' && state === 'opened') {
      mediaSourceState.current.timing.initToOpen = currentTime - performanceMetrics.current.initializationTime;
    } else if (prevState === 'opened' && state === 'buffer-created') {
      mediaSourceState.current.timing.openToBuffer = currentTime - performanceMetrics.current.mediaSourceOpenTime;
    } else if (prevState === 'buffer-created' && state === 'buffer-ready') {
      mediaSourceState.current.timing.bufferToReady = currentTime - mediaSourceState.current.stateChanges[mediaSourceState.current.stateChanges.length - 1].timestamp;
    }

    mediaSourceState.current = {
      ...mediaSourceState.current,
      lastState: state,
      lastError: error,
      stateChanges: [
        ...mediaSourceState.current.stateChanges,
        {
          state,
          error,
          timestamp: currentTime
        }
      ]
    };

    console.log('MediaSource state change:', {
      state,
      error,
      timing: mediaSourceState.current.timing,
      history: mediaSourceState.current.stateChanges,
      timestamp: currentTime
    });
  };

  const trackSocketState = (state, error = null) => {
    const currentTime = Date.now();
    const prevState = socketState.current.lastState;

    // Calculate timing between state transitions
    if (prevState === 'connecting' && state === 'connected') {
      socketState.current.timing.connectToUpgrade = currentTime - performanceMetrics.current.socketConnectTime;
    } else if (prevState === 'connected' && state === 'upgraded') {
      socketState.current.timing.upgradeToData = currentTime - socketState.current.stateChanges[socketState.current.stateChanges.length - 1].timestamp;
    }

    socketState.current = {
      ...socketState.current,
      lastState: state,
      lastError: error,
      stateChanges: [
        ...socketState.current.stateChanges,
        {
          state,
          error,
          timestamp: currentTime
        }
      ]
    };

    console.log('Socket state change:', {
      state,
      error,
      timing: socketState.current.timing,
      history: socketState.current.stateChanges,
      timestamp: currentTime
    });
  };

  const initializeMediaSource = () => {
    try {
      const initStartTime = Date.now();
      trackPerformanceMetric('mediaSourceInit', initStartTime);
      console.log('Initializing MediaSource...', {
        attempt: initializationAttempts.current + 1,
        maxAttempts: maxInitializationAttempts,
        timestamp: initStartTime
      });
      initializationAttempts.current++;
      trackMediaSourceState('initializing');

      // Clean up existing MediaSource if it exists
      cleanupMediaSource();

      // Create new MediaSource
      mediaSourceRef.current = new MediaSource();
      
      // Set up MediaSource event handlers
      const handleSourceOpen = () => {
        try {
          trackMediaSourceState('opened');
          trackPerformanceMetric('mediaSourceOpen', Date.now());
          console.log('MediaSource opened, creating SourceBuffer', {
            readyState: mediaSourceRef.current?.readyState,
            timestamp: Date.now()
          });
          // Try different codec combinations
          const codecs = [
            'video/webm;codecs=vp8,opus',
            'video/webm;codecs=vp9,opus',
            'video/webm;codecs=vp8',
            'video/webm'
          ];
          
          let sourceBufferCreated = false;
          for (const codec of codecs) {
            try {
              sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer(codec);
              console.log('SourceBuffer created successfully with codec:', codec);
              sourceBufferCreated = true;
              break;
            } catch (e) {
              console.log('Failed to create SourceBuffer with codec:', codec, e);
            }
          }

          if (!sourceBufferCreated) {
            throw new Error('Failed to create SourceBuffer with any supported codec');
          }

          // Add updateend event listener to the SourceBuffer
          sourceBufferRef.current.addEventListener('updateend', () => {
            trackMediaSourceState('buffer-created');
            console.log('SourceBuffer update ended', {
              buffered: sourceBufferRef.current.buffered.length > 0 ? {
                start: sourceBufferRef.current.buffered.start(0),
                end: sourceBufferRef.current.buffered.end(0)
              } : 'No buffered data',
              timestamp: Date.now()
            });
            isSourceBufferReady.current = true;
            processStreamQueue();
          });

          // Add error event listener to the SourceBuffer
          sourceBufferRef.current.addEventListener('error', (e) => {
            trackMediaSourceState('buffer-error', e);
            console.error('SourceBuffer error:', e);
            isSourceBufferReady.current = true;
          });

          isSourceBufferReady.current = true;
          // Process any queued data
          processStreamQueue();
        } catch (e) {
          trackMediaSourceState('error', e);
          console.error('Error creating SourceBuffer:', e);
          setErrorWithLog('Failed to initialize video player', { error: e });
          if (initializationAttempts.current < maxInitializationAttempts) {
            console.log('Retrying MediaSource initialization...');
            setTimeout(initializeMediaSource, 1000);
          }
        }
      };

      const handleSourceEnded = () => {
        console.log('MediaSource ended, current state:', mediaSourceRef.current.readyState);
        isSourceBufferReady.current = false;
        // Try to recover if we're still connected
        if (isConnected && initializationAttempts.current < maxInitializationAttempts) {
          console.log('Attempting to recover MediaSource...');
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          reconnectTimeoutRef.current = setTimeout(() => {
            if (isConnected && !isSourceBufferReady.current) {
              console.log('Reinitializing MediaSource after timeout');
              initializeMediaSource();
            }
          }, 1000);
        }
      };

      const handleSourceClose = () => {
        console.log('MediaSource closed, current state:', mediaSourceRef.current.readyState);
        isSourceBufferReady.current = false;
        // Try to recover if we're still connected
        if (isConnected && initializationAttempts.current < maxInitializationAttempts) {
          console.log('Attempting to recover MediaSource...');
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          reconnectTimeoutRef.current = setTimeout(() => {
            if (isConnected && !isSourceBufferReady.current) {
              console.log('Reinitializing MediaSource after timeout');
              initializeMediaSource();
            }
          }, 1000);
        }
      };

      // Add event listeners
      mediaSourceRef.current.addEventListener('sourceopen', handleSourceOpen);
      mediaSourceRef.current.addEventListener('sourceended', handleSourceEnded);
      mediaSourceRef.current.addEventListener('sourceclose', handleSourceClose);

      // Create object URL and set video source
      if (videoRef.current) {
        videoRef.current.src = URL.createObjectURL(mediaSourceRef.current);
      }

    } catch (e) {
      trackMediaSourceState('error', e);
      console.error('Error initializing MediaSource:', e);
      setErrorWithLog('Failed to initialize video player', { error: e });
      if (initializationAttempts.current < maxInitializationAttempts) {
        console.log('Retrying MediaSource initialization...');
        setTimeout(initializeMediaSource, 1000);
      }
    }
  };

  // Add buffer configuration
  const bufferConfig = {
    targetBufferSize: 2.0, // Target 2 seconds of buffer
    minBufferSize: 0.5,   // Minimum 500ms buffer
    maxBufferSize: 4.0,   // Maximum 4 seconds buffer
    underflowThreshold: 0.5, // Warning threshold
    playbackRates: {
      normal: 1.0,
      catchup: 1.1,    // Speed up to catch up
      slowdown: 0.9    // Slow down if buffer is low
    }
  };

  // Enhanced buffer health tracking
  const updateBufferHealth = () => {
    if (videoRef.current && sourceBufferRef.current) {
      const buffered = sourceBufferRef.current.buffered;
      const currentTime = videoRef.current.currentTime;
      
      if (buffered.length > 0) {
        const bufferEnd = buffered.end(buffered.length - 1);
        const bufferStart = buffered.start(0);
        const bufferDuration = bufferEnd - bufferStart;
        const bufferAhead = bufferEnd - currentTime;
        
        // Update buffer health metrics
        performanceMetrics.current.dataMetrics.bufferHealth = {
          ...performanceMetrics.current.dataMetrics.bufferHealth,
          bufferSize: bufferDuration,
          bufferDuration: bufferAhead,
          timestamp: Date.now()
        };

        // Adjust playback rate based on buffer health
        if (videoRef.current) {
          if (bufferAhead < bufferConfig.minBufferSize) {
            // Buffer critically low, slow down playback
            videoRef.current.playbackRate = bufferConfig.playbackRates.slowdown;
            performanceMetrics.current.dataMetrics.bufferHealth.underflowCount++;
            performanceMetrics.current.dataMetrics.bufferHealth.lastUnderflow = Date.now();
            console.warn('Buffer underflow warning:', {
              bufferAhead,
              currentTime,
              bufferEnd,
              playbackRate: videoRef.current.playbackRate,
              metrics: performanceMetrics.current.dataMetrics.bufferHealth
            });
          } else if (bufferAhead > bufferConfig.targetBufferSize) {
            // Buffer healthy, can speed up if we're behind
            videoRef.current.playbackRate = bufferConfig.playbackRates.catchup;
          } else {
            // Buffer in normal range
            videoRef.current.playbackRate = bufferConfig.playbackRates.normal;
          }
        }

        // Log if buffer is getting too full
        if (bufferDuration > bufferConfig.maxBufferSize) {
          performanceMetrics.current.dataMetrics.bufferHealth.overflowCount++;
          performanceMetrics.current.dataMetrics.bufferHealth.lastOverflow = Date.now();
          console.warn('Buffer overflow warning:', {
            bufferDuration,
            currentTime,
            metrics: performanceMetrics.current.dataMetrics.bufferHealth
          });

          // Remove excess buffer
          if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
            const removeEnd = bufferStart + (bufferDuration / 2);
            try {
              sourceBufferRef.current.remove(bufferStart, removeEnd);
            } catch (e) {
              console.error('Error trimming buffer:', e);
            }
          }
        }
      }
    }
  };

  // Update video event handlers
  const handleWaiting = () => {
    const currentTime = Date.now();
    performanceMetrics.current.dataMetrics.playbackMetrics.stallCount++;
    performanceMetrics.current.dataMetrics.playbackMetrics.lastStallTime = currentTime;
    
    console.log('Video waiting for data:', {
      readyState: videoRef.current.readyState,
      networkState: videoRef.current.networkState,
      currentTime: videoRef.current.currentTime,
      buffered: videoRef.current.buffered.length > 0 ? {
        start: videoRef.current.buffered.start(0),
        end: videoRef.current.buffered.end(videoRef.current.buffered.length - 1)
      } : 'No buffered data',
      metrics: {
        bufferHealth: performanceMetrics.current.dataMetrics.bufferHealth,
        playbackMetrics: performanceMetrics.current.dataMetrics.playbackMetrics
      },
      timestamp: currentTime
    });
    updateBufferHealth();
  };

  const handlePlaying = () => {
    const currentTime = Date.now();
    if (!performanceMetrics.current.firstFrameTime) {
      trackPerformanceMetric('firstFrame', currentTime);
    }
    
    // Calculate stall duration if we were stalled
    if (performanceMetrics.current.dataMetrics.playbackMetrics.lastStallTime) {
      const stallDuration = currentTime - performanceMetrics.current.dataMetrics.playbackMetrics.lastStallTime;
      performanceMetrics.current.dataMetrics.playbackMetrics.totalStallTime += stallDuration;
      performanceMetrics.current.dataMetrics.playbackMetrics.averageStallDuration = 
        performanceMetrics.current.dataMetrics.playbackMetrics.totalStallTime / 
        performanceMetrics.current.dataMetrics.playbackMetrics.stallCount;
    }

    console.log('Video playing:', {
      readyState: videoRef.current.readyState,
      networkState: videoRef.current.networkState,
      currentTime: videoRef.current.currentTime,
      duration: videoRef.current.duration,
      playbackRate: videoRef.current.playbackRate,
      metrics: {
        bufferHealth: performanceMetrics.current.dataMetrics.bufferHealth,
        playbackMetrics: performanceMetrics.current.dataMetrics.playbackMetrics
      },
      timestamp: currentTime
    });
    
    setVideoStateWithLog('playing', { 
      event: 'playing',
      metrics: performanceMetrics.current.dataMetrics
    });
  };

  // Add periodic buffer health check
  useEffect(() => {
    const bufferHealthInterval = setInterval(() => {
      if (videoRef.current && sourceBufferRef.current) {
        updateBufferHealth();
      }
    }, 1000);

    return () => clearInterval(bufferHealthInterval);
  }, []);

  useEffect(() => {
    console.log('Watch component mounted, initializing...', {
      renderCount: renderCount.current,
      timestamp: Date.now()
    });
    
    trackPerformanceMetric('componentMount', Date.now());
    
    // Initialize Socket.IO connection
    console.log('Creating Socket.IO connection to:', API_URL);
    const newSocket = io(API_URL, {
      path: '/socket.io/',
      transports: ['polling', 'websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      autoConnect: true,
      withCredentials: true,
      forceNew: true,
      multiplex: false,
      upgrade: true,
      rememberUpgrade: true,
      perMessageDeflate: false,
      maxHttpBufferSize: 1e8,
      secure: true,
      rejectUnauthorized: false,
      // Binary support configuration
      binaryType: 'arraybuffer'
    });

    // Track connection state with binary support info
    const connectionState = {
      connected: false,
      registered: false,
      broadcasterAvailable: false,
      binaryType: null,
      supportsBinary: false,
      registrationState: 'idle', // idle, pending, registered, error
      registrationAttempts: 0,
      maxRegistrationAttempts: 3,
      lastRegistrationAttempt: 0,
      registrationTimeout: 15000, // 15 seconds
      registrationDelay: 3000,   // 3 seconds between attempts
      initialRegistrationDelay: 5000, // 5 seconds before first attempt
      pendingRegistration: false,
      pendingRegistrationTimeout: null,
      lastBroadcasterAvailable: 0,
      lastTransportUpgrade: 0,
      lastViewerRegistered: 0,
      registrationRetryDelay: 3000, // 3 seconds between retries
      lastRegistrationError: null,
      registrationErrorCount: 0,
      maxRegistrationErrors: 3,
      registrationHistory: [],
      lastTransportState: null,
      transportUpgradeTime: 0,
      lastErrorTime: 0,
      errorRetryDelay: 5000 // 5 seconds after error
    };

    // Helper function to update registration state
    const updateRegistrationState = (newState, error = null) => {
      const currentTime = Date.now();
      const prevState = connectionState.registrationState;
      connectionState.registrationState = newState;
      connectionState.lastRegistrationError = error;
      
      if (error) {
        connectionState.registrationErrorCount++;
        connectionState.lastErrorTime = currentTime;
      }
      
      connectionState.registrationHistory.push({
        state: newState,
        error,
        timestamp: currentTime,
        socketId: newSocket.id,
        transport: newSocket.io.engine.transport.name,
        transportState: connectionState.lastTransportState,
        timeSinceUpgrade: currentTime - connectionState.transportUpgradeTime,
        errorCount: connectionState.registrationErrorCount,
        timeSinceLastError: currentTime - connectionState.lastErrorTime
      });
      
      console.log('Registration state changed:', {
        from: prevState,
        to: newState,
        error,
        history: connectionState.registrationHistory,
        timestamp: currentTime,
        transportState: connectionState.lastTransportState,
        timeSinceUpgrade: currentTime - connectionState.transportUpgradeTime,
        errorCount: connectionState.registrationErrorCount,
        timeSinceLastError: currentTime - connectionState.lastErrorTime
      });
    };

    // Helper function to attempt registration
    const attemptRegistration = (isRetry = false) => {
      if (connectionState.registrationState === 'registered') {
        console.log('Already registered, skipping registration attempt');
        return;
      }

      if (connectionState.registrationErrorCount >= connectionState.maxRegistrationErrors) {
        console.error('Max registration errors reached, not attempting registration');
        return;
      }

      const currentTime = Date.now();
      const timeSinceLastAttempt = currentTime - connectionState.lastRegistrationAttempt;
      const timeSinceUpgrade = currentTime - connectionState.transportUpgradeTime;
      const timeSinceLastError = currentTime - connectionState.lastErrorTime;

      // Check if we need to wait
      if (isRetry && timeSinceLastAttempt < connectionState.registrationDelay) {
        console.log('Waiting for registration delay before retry:', {
          timeSinceLastAttempt,
          requiredDelay: connectionState.registrationDelay
        });
        return;
      }

      // For first attempt after upgrade, wait for initial delay
      if (!isRetry && timeSinceUpgrade < connectionState.initialRegistrationDelay) {
        console.log('Waiting for initial registration delay:', {
          timeSinceUpgrade,
          requiredDelay: connectionState.initialRegistrationDelay
        });
        return;
      }

      // After an error, wait for error retry delay
      if (connectionState.lastErrorTime > 0 && timeSinceLastError < connectionState.errorRetryDelay) {
        console.log('Waiting for error retry delay:', {
          timeSinceLastError,
          requiredDelay: connectionState.errorRetryDelay
        });
        return;
      }

      connectionState.lastRegistrationAttempt = currentTime;
      connectionState.registrationAttempts++;
      connectionState.pendingRegistration = true;
      updateRegistrationState('pending');

      // Set timeout for pending registration
      if (connectionState.pendingRegistrationTimeout) {
        clearTimeout(connectionState.pendingRegistrationTimeout);
      }
      connectionState.pendingRegistrationTimeout = setTimeout(() => {
        if (connectionState.registrationState === 'pending') {
          console.warn('Registration attempt timed out:', {
            socketId: newSocket.id,
            attempt: connectionState.registrationAttempts,
            timeSinceAttempt: Date.now() - connectionState.lastRegistrationAttempt,
            broadcasterAvailable: connectionState.broadcasterAvailable,
            timeSinceBroadcaster: Date.now() - connectionState.lastBroadcasterAvailable,
            transport: newSocket.io.engine.transport.name,
            timeSinceLastRegistered: Date.now() - connectionState.lastViewerRegistered,
            errorCount: connectionState.registrationErrorCount,
            transportState: connectionState.lastTransportState,
            timeSinceUpgrade: Date.now() - connectionState.transportUpgradeTime,
            timeSinceLastError: Date.now() - connectionState.lastErrorTime
          });
          connectionState.pendingRegistration = false;
          updateRegistrationState('error', 'timeout');
          
          // Retry registration after delay if not too many errors
          if (connectionState.registrationErrorCount < connectionState.maxRegistrationErrors) {
            setTimeout(() => {
              if (connectionState.registrationState !== 'registered') {
                console.log('Retrying registration after timeout...');
                attemptRegistration(true);
              }
            }, connectionState.registrationRetryDelay);
          } else {
            console.error('Max registration errors reached:', {
              errors: connectionState.registrationErrorCount,
              maxErrors: connectionState.maxRegistrationErrors,
              socketId: newSocket.id,
              timeSinceLastAttempt: Date.now() - connectionState.lastRegistrationAttempt,
              transportState: connectionState.lastTransportState,
              timeSinceUpgrade: Date.now() - connectionState.transportUpgradeTime,
              timeSinceLastError: Date.now() - connectionState.lastErrorTime
            });
            setErrorWithLog('Failed to register as viewer after multiple errors', {
              errors: connectionState.registrationErrorCount,
              socketId: newSocket.id
            });
          }
        }
      }, connectionState.registrationTimeout);

      newSocket.emit('register-viewer', { 
        supportsBinary: true,
        attempt: connectionState.registrationAttempts,
        socketId: newSocket.id,
        transport: newSocket.io.engine.transport.name,
        lastRegistered: connectionState.lastViewerRegistered,
        isRetry: isRetry,
        errorCount: connectionState.registrationErrorCount,
        state: connectionState.registrationState,
        transportState: connectionState.lastTransportState,
        timeSinceUpgrade: currentTime - connectionState.transportUpgradeTime,
        timeSinceLastError: currentTime - connectionState.lastErrorTime
      });
    };

    // Add connection state logging with binary support
    newSocket.io.engine.on('upgrade', (transport) => {
      socketState.current.upgrades.push({
        transport: transport.name,
        timestamp: Date.now()
      });
      
      // Check binary support after upgrade
      connectionState.binaryType = transport.socket?.binaryType;
      connectionState.supportsBinary = transport.supportsBinary;
      connectionState.lastTransportUpgrade = Date.now();
      connectionState.transportUpgradeTime = Date.now();
      connectionState.lastTransportState = transport.name;
      
      console.log('Transport upgraded with binary support:', {
        transport: transport.name,
        binaryType: connectionState.binaryType,
        supportsBinary: connectionState.supportsBinary,
        timestamp: Date.now()
      });

      trackSocketState('upgraded', { 
        transport: transport.name,
        binarySupport: {
          binaryType: connectionState.binaryType,
          supportsBinary: connectionState.supportsBinary
        }
      });
      trackPerformanceMetric('socketUpgrade', Date.now());

      // Re-register after transport upgrade if not already registered
      if (connectionState.connected && connectionState.registrationState !== 'registered') {
        console.log('Re-registering after transport upgrade...');
        attemptRegistration(true);
      }
    });

    // Socket connection handlers
    newSocket.on('connect', () => {
      connectionState.connected = true;
      connectionState.registrationAttempts = 0;
      connectionState.pendingRegistration = false;
      connectionState.registrationErrorCount = 0;
      connectionState.lastErrorTime = 0;
      if (connectionState.pendingRegistrationTimeout) {
        clearTimeout(connectionState.pendingRegistrationTimeout);
        connectionState.pendingRegistrationTimeout = null;
      }
      updateRegistrationState('idle');
      trackSocketState('connected');
      trackPerformanceMetric('socketConnect', Date.now());
      console.log('Socket.IO connected:', {
        socketId: newSocket.id,
        transport: newSocket.io.engine.transport.name,
        readyState: newSocket.io.engine.readyState,
        connectionState,
        timestamp: Date.now()
      });

      // Register as viewer with binary support flag
      console.log('Registering as viewer after connect...');
      attemptRegistration(false);

      // Request broadcaster status
      console.log('Requesting broadcaster status...');
      newSocket.emit('check-broadcaster');
    });

    newSocket.on('broadcaster-available', () => {
      const currentTime = Date.now();
      connectionState.broadcasterAvailable = true;
      connectionState.lastBroadcasterAvailable = currentTime;
      console.log('Broadcaster is available:', {
        socketId: newSocket.id,
        connectionState,
        timestamp: currentTime
      });

      setIsConnectedWithLog(true, { 
        event: 'broadcaster-available',
        socketId: newSocket.id,
        connectionState
      });
      setErrorWithLog(null, { event: 'broadcaster-available' });

      // Try to register if not already registered
      if (connectionState.registrationState !== 'registered' && !connectionState.pendingRegistration) {
        console.log('Attempting registration after broadcaster available...');
        attemptRegistration(false);
      }
    });

    newSocket.on('viewer-registered', () => {
      const currentTime = Date.now();
      console.log('Viewer registration acknowledged, initializing media pipeline...', {
        socketId: newSocket.id,
        connectionState,
        timestamp: currentTime
      });
      
      connectionState.registered = true;
      connectionState.registrationAttempts = 0;
      connectionState.pendingRegistration = false;
      connectionState.lastViewerRegistered = currentTime;
      connectionState.registrationErrorCount = 0;
      if (connectionState.pendingRegistrationTimeout) {
        clearTimeout(connectionState.pendingRegistrationTimeout);
        connectionState.pendingRegistrationTimeout = null;
      }
      updateRegistrationState('registered');
      trackSocketState('registered');

      // Initialize MediaSource immediately after registration
      console.log('Initializing MediaSource after registration...');
      initializeMediaSource();

      // Process any queued data
      if (streamQueue.current.rawDataQueue?.length > 0) {
        console.log('Processing queued raw data:', {
          queueLength: streamQueue.current.rawDataQueue.length,
          timestamp: currentTime
        });
        const queuedData = streamQueue.current.rawDataQueue;
        streamQueue.current.rawDataQueue = [];
        queuedData.forEach(data => processStreamData(data, currentTime));
      }
    });

    // Enhanced stream data handling with binary support
    newSocket.on('stream-data', (data) => {
      const currentTime = Date.now();
      
      // Queue data if not registered
      if (connectionState.registrationState !== 'registered') {
        console.log('Connection not registered, queuing data:', {
          dataSize: data?.byteLength || data?.length || 0,
          queueLength: streamQueue.current.rawDataQueue?.length || 0,
          connectionState,
          timeSinceLastAttempt: currentTime - connectionState.lastRegistrationAttempt,
          pendingRegistration: connectionState.pendingRegistration,
          registrationTimeout: connectionState.pendingRegistrationTimeout ? 'active' : 'none',
          timeSinceBroadcaster: currentTime - connectionState.lastBroadcasterAvailable,
          timeSinceUpgrade: currentTime - connectionState.lastTransportUpgrade,
          timeSinceLastRegistered: currentTime - connectionState.lastViewerRegistered,
          errorCount: connectionState.registrationErrorCount,
          registrationState: connectionState.registrationState
        });
        
        if (!streamQueue.current.rawDataQueue) {
          streamQueue.current.rawDataQueue = [];
        }
        streamQueue.current.rawDataQueue.push(data);
        
        // Re-register if needed and not too many errors
        if (connectionState.connected && 
            !connectionState.pendingRegistration &&
            connectionState.registrationErrorCount < connectionState.maxRegistrationErrors &&
            currentTime - connectionState.lastRegistrationAttempt > connectionState.registrationDelay) {
          console.log('Re-registering as viewer...', {
            attempt: connectionState.registrationAttempts + 1,
            maxErrors: connectionState.maxRegistrationErrors,
            timeSinceLastAttempt: currentTime - connectionState.lastRegistrationAttempt,
            socketId: newSocket.id,
            timeSinceBroadcaster: currentTime - connectionState.lastBroadcasterAvailable,
            timeSinceUpgrade: currentTime - connectionState.lastTransportUpgrade,
            timeSinceLastRegistered: currentTime - connectionState.lastViewerRegistered,
            errorCount: connectionState.registrationErrorCount,
            registrationState: connectionState.registrationState
          });
          
          attemptRegistration(true);
        } else if (connectionState.registrationErrorCount >= connectionState.maxRegistrationErrors) {
          console.error('Max registration errors reached:', {
            errors: connectionState.registrationErrorCount,
            maxErrors: connectionState.maxRegistrationErrors,
            timeSinceLastAttempt: currentTime - connectionState.lastRegistrationAttempt,
            pendingRegistration: connectionState.pendingRegistration,
            socketId: newSocket.id,
            timeSinceBroadcaster: currentTime - connectionState.lastBroadcasterAvailable,
            timeSinceUpgrade: currentTime - connectionState.lastTransportUpgrade,
            timeSinceLastRegistered: currentTime - connectionState.lastViewerRegistered,
            registrationState: connectionState.registrationState
          });
          setErrorWithLog('Failed to register as viewer after multiple errors', {
            errors: connectionState.registrationErrorCount,
            socketId: newSocket.id
          });
        }
        return;
      }

      // Process data if registered
      processStreamData(data, currentTime);
    });

    // Enhanced processStreamData function
    const processStreamData = (data, timestamp) => {
      if (!data) {
        console.warn('Received null or undefined data');
        return;
      }

      // Convert data to Uint8Array if needed
      let processedData;
      try {
        if (data instanceof ArrayBuffer) {
          processedData = new Uint8Array(data);
        } else if (data instanceof Uint8Array) {
          processedData = data;
        } else if (data?.buffer instanceof ArrayBuffer) {
          processedData = new Uint8Array(data.buffer);
        } else if (Array.isArray(data)) {
          processedData = new Uint8Array(data);
        } else if (typeof data === 'object' && data.type === 'Buffer' && Array.isArray(data.data)) {
          processedData = new Uint8Array(data.data);
        } else {
          console.error('Unhandled data format:', {
            type: typeof data,
            constructor: data?.constructor?.name,
            byteLength: data?.byteLength,
            isArrayBuffer: data instanceof ArrayBuffer,
            hasBuffer: data?.buffer instanceof ArrayBuffer
          });
          return;
        }

        if (processedData.length === 0) {
          console.warn('Processed data is empty');
          return;
        }

        console.log('Successfully processed stream data:', {
          originalType: typeof data,
          processedType: processedData.constructor.name,
          length: processedData.length,
          firstBytes: Array.from(processedData.slice(0, 16))
        });

        // Add to processing queue
        streamQueue.current.push(processedData);
        
        // Process queue if ready
        if (isSourceBufferReady.current && !sourceBufferRef.current?.updating) {
          processStreamQueue().catch(err => {
            console.error('Error processing stream queue:', err);
          });
        } else {
          console.log('Queued data for later processing:', {
            queueLength: streamQueue.current.length,
            isSourceBufferReady: isSourceBufferReady.current,
            isUpdating: sourceBufferRef.current?.updating
          });
        }
      } catch (error) {
        console.error('Error processing stream data:', {
          error: error.message,
          stack: error.stack,
          dataType: typeof data,
          constructor: data?.constructor?.name
        });
      }
    };

    // Add video element event listeners
    if (videoRef.current) {
      videoRef.current.addEventListener('loadstart', () => {
        console.log('Video loadstart:', {
          readyState: videoRef.current.readyState,
          networkState: videoRef.current.networkState,
          currentTime: videoRef.current.currentTime,
          paused: videoRef.current.paused,
          mediaSource: {
            readyState: mediaSourceRef.current?.readyState,
            hasSourceBuffer: !!sourceBufferRef.current,
            isSourceBufferReady: isSourceBufferReady.current
          }
        });
      });

      videoRef.current.addEventListener('loadedmetadata', () => {
        console.log('Video loadedmetadata:', {
          readyState: videoRef.current.readyState,
          networkState: videoRef.current.networkState,
          duration: videoRef.current.duration,
          videoWidth: videoRef.current.videoWidth,
          videoHeight: videoRef.current.videoHeight
        });
      });

      videoRef.current.addEventListener('error', (e) => {
        console.error('Video error:', {
          error: videoRef.current.error,
          errorCode: videoRef.current.error?.code,
          errorMessage: videoRef.current.error?.message,
          mediaSource: {
            readyState: mediaSourceRef.current?.readyState,
            hasSourceBuffer: !!sourceBufferRef.current,
            isSourceBufferReady: isSourceBufferReady.current
          }
        });
      });
    }

    setSocket(newSocket);

    // Add a timeout to check connection status
    const connectionCheckTimeout = setTimeout(() => {
      console.log('Connection check after timeout:', {
        connected: newSocket.connected,
        disconnected: newSocket.disconnected,
        socketId: newSocket.id,
        transport: newSocket.io.engine.transport.name,
        readyState: newSocket.io.engine.readyState,
        headers: newSocket.io.engine.headers,
        upgrade: newSocket.io.engine.upgrading,
        upgrades: newSocket.io.engine.upgrades
      });
      if (!newSocket.connected) {
        console.log('Socket not connected after timeout, attempting to reconnect...');
        newSocket.connect();
      }
    }, 5000);

    // Cleanup on unmount
    return () => {
      console.log('Watch component unmounting, cleaning up...', {
        renderCount: renderCount.current,
        timestamp: Date.now()
      });
      clearTimeout(connectionCheckTimeout);
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      cleanupMediaSource();
      if (videoRef.current) {
        videoRef.current.src = '';
      }
      newSocket.close();
    };
  }, []);

  console.log('Watch component before render:', {
    reason: renderReason.current,
    context: renderContext.current,
    states: {
      isConnected,
      videoState,
      error,
      hasMediaSource: !!mediaSourceRef.current,
      hasSourceBuffer: !!sourceBufferRef.current,
      isSourceBufferReady: isSourceBufferReady.current,
      queueLength: streamQueue.current?.length,
      mediaSourceState: mediaSourceState.current,
      socketState: socketState.current,
      performanceMetrics: performanceMetrics.current
    },
    renderCount: renderCount.current,
    renderTimes: renderTimes.current,
    timestamp: Date.now()
  });

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Live Stream
        </Typography>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              style={{
                width: '100%',
                maxWidth: '640px',
                borderRadius: '8px',
                backgroundColor: '#000',
                aspectRatio: '16/9'
              }}
            />
            {!isConnected && !error && (
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                Waiting for stream to start...
              </Typography>
            )}
            {error && (
              <Typography variant="body1" color="error" sx={{ mt: 2, textAlign: 'center' }}>
                {error}
              </Typography>
            )}
            {isConnected && videoState === 'waiting' && (
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                Receiving stream data...
              </Typography>
            )}
            {isConnected && videoState === 'receiving' && (
              <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
                Processing video stream...
              </Typography>
            )}
            {isConnected && videoState === 'playing' && (
              <Typography variant="body1" color="success.main" sx={{ mt: 2, textAlign: 'center' }}>
                Connected to live stream
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default React.memo(Watch, (prevProps, nextProps) => {
  console.log('Watch memo comparison:', {
    prevProps,
    nextProps,
    timestamp: Date.now()
  });
  return true; // Only re-render if props actually change
}); 